<template>
  <div>
    <List ref="list"></List>
    <Check></Check>
  </div>
</template>

<script>
import List from '../components/project/list.vue'
import Check from '../components/project/check.vue'

export default {
  name: 'App',
  components: {
    List,
    Check
  },
  methods: {
    loadData() {
      console.log("loadData project list")
    },
    handleOpen() {

    },
    saveSuccess() {
      this.$refs.list.search()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>>