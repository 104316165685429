<template>
  <div class="list">
    
    <el-card class="query-box">

      <div class="category-box">
        <l-category class="title" :from="'home'" :name="currentFirstCategory.name || '分类：'" @selectFirst="selectFirst" @selectSecond="selectSecond"></l-category>
        <label v-for="second in currentFirstCategory.children" :key="'category' + second.id" @click="selectSecond(second)" class="content" :style="second.id === currentSecondCategory.id ? 'font-weight: bold;color: orange;': ''">{{second.name}}</label>
      </div>
      <div class="city-box">
        <l-city class="title" :from="'home'" :name="currentProvince.name || '地点'" @selectProvince="selectProvince" @selectCity="selectCity" ></l-city>
        <label v-for="city in currentProvince.citys" :key="'city' + city.id" @click="selectCurrentCity(city)" class="content" :style="city.id === currentCity.id ? 'font-weight: bold;color: orange;': ''">{{city.name}}</label>
      </div>
    </el-card>

    
    <div ref="listBox" class="list-container-box">
        
        <div class="list-container">
          <el-card v-for="(item, index) in projectList" :key="'list' + item.id" class="item-card" :style="index % 2 === 1 ? 'float: right;' : ''">
            <div class="item-box">
              <el-image :src="item.cover_img" class="cover-img"></el-image>
              <div class="item-content">
                <div class="item-title" @click="clickItem(item)">{{item.project_desc}}</div>
                <div class="item-tags" v-if="item.skill_label">
                    <el-tag class="tag-item" size="small" v-for="(tag, index) in (item.skill_label.split(','))" :key="item.id + '-' + index">{{tag}}</el-tag>
                </div>
                <div class="item-address">{{item.address}}</div>
                <div class="mobile-box" @click="mobile(item)">马上联系<i class="el-icon-phone-outline mobile"></i></div>
              </div>
              
            </div>
            
          </el-card>
          <div v-if="!skillHasNext && projectList.length > 0" class="bottom-box">已经没有更多数据啦~~</div>
          <div v-if="showTop" class="to-top" @click="toTop">
            <el-image class="top-img" :src="top"></el-image>
            <div class="top-txt">回到顶部</div>
          </div>
          <el-card 
            v-if="loading"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgb(4 4 4 / 0%)"
            class="item-card">
          </el-card>
          <el-card 
            v-if="projectList.length === 0 && !loading"
            class="item-card-simple">
            <el-empty v-if="projectList.length === 0 && !loading" description="暂无数据 ~~ "></el-empty>
          </el-card>
        </div>
    </div>
    
    

    <el-dialog
      title="拨打电话"
      :visible.sync="showMobile"
      width="400px"
      center>
      <div class="mobile-dialog">
        <div class="mobile-content">{{currentMobile}}</div>
        <label class="mobile-desc">电话有效期还剩（</label>
        <label class="mobile-second">{{mobileSecond}}</label>
        <label class="mobile-desc">）秒，请尽快拨打</label>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMobile = false">关 闭</el-button>
        <el-button type="primary" @click="showMobile = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import Category from "../category/tree.vue"
import City from "../city/tree.vue"

export default {
  components: {
    
    "l-category": Category,
    "l-city": City,
  },
  data() {
    return {
      top: require('../../assets/top.png'),
      listContainerWidth: 0,
      showMobile: false,
      formInline: {
        province_id: '',
        city_id: '',
        skill_type_id: '',
        page: 1
      },
      
      statusMap: {
        "0": "未审核",
        "1": "审核通过",
        "-1": "审核未通过",
      },
      mobileSecond: 0,
      mobileInternal: undefined,
      currentMobile: 0,
      loadPageFlag: true,
      toToping: false,
      isBottom: false,
      canTop: false,
    }
  },
  computed: {
    
    ...mapState({
      currentHeight: (state) => state.project.currentHeight,
      skillHasNext: (state) => state.project.skillHasNext,
      loading: (state) => state.project.loading,
      projectList: (state) => state.project.projectList || [],
      currentProvince: (state) => state.project.currentProvince || {},
      currentCity: (state) => state.project.currentCity || {},
      currentFirstCategory: (state) => state.project.currentFirstCategory || {},
      currentSecondCategory: (state) => state.project.currentSecondCategory || {},
    }),
    showTop() {
      
      return this.canTop
    }
    
  },
  
  mounted() {
    
    
  },
  methods: {
    
    toTop() {
      if (this.toToping) {
        return
      }
      var timer = setInterval(function(){
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5); 
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          this.toToping = true;
          if(osTop === 0){
            this.toToping = false;
            clearInterval(timer);
          }
      },30) 
    },
    initPageComponent() {
      let callback = () => {
        
        this.loadData()
      }
      
      let canTop = (val) => {
        this.canTop = val
      }

      window.onscroll = function() {
        console.log(document.body)
        
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        
        var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        
        var offsetHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
       
        console.log(`${scrollTop}-${clientHeight}-${offsetHeight}`)
        if (scrollTop > clientHeight * 1.5) {
          canTop(true)
        } else {
          canTop(false)
        }
        if (scrollTop > 5 && (Math.abs(scrollTop + clientHeight - offsetHeight) < 5)) {
            
          callback()
        }
      }
    },
    loadData(refresh) {
      this.$nextTick(() => {
        if (this.loadPageFlag) {
          this.loadPageFlag = false
          this.$store.dispatch("project/searchProjects", {refresh: refresh}).then(() => {
            this.loadPageFlag = true
            if (refresh) {
              this.initPageComponent()
            }
          })
        }
      })
      
      
    },
    
    clickItem(item) {
      // this.$store.commit('project/check', item.id)
      this.$router.push({path: "/detail", query: { 
        id: item.id 
      }})
    },
    
    
    pageChange(page) {
      this.formInline.page = page
      this.search()
    },
    selectFirst(first) {
      this.$store.commit("project/selectFirstCategory", first)
        
        
    },
    selectSecond(second) {
        this.$store.commit("project/selectSecondCategory", second)
        this.$nextTick(() => {
          this.loadData(true)
        })
        
          
    },
    selectProvince(province) {
      this.$store.commit("project/selectProvince", province)
      this.$nextTick(() => {
        this.loadData(true)
      })
      
    },
    selectCity(position) {
      console.log(position)
        this.$store.commit("project/selectProvince", position.province)
        this.$store.commit("project/selectCity", position.city)
        this.$nextTick(() => {
          this.loadData(true)
        })
          
    },
    selectCurrentCity(city) {
       
        this.$store.commit("project/selectCity", city)
        this.$nextTick(() => {
          this.loadData(true)
        })
          
    },
    mobile(item) {
      this.currentMobile = item.mobile
      this.showMobile = true
      this.mobileSecond = 180
      if (this.mobileInternal) {
        clearInterval(this.mobileInternal)
      }
      this.mobileInternal = setInterval(() => {
        this.mobileSecond -= 1
      }, 1000);
    }
    
  }
}
</script>

<style lang="scss" scoped>
.list-container-box {
    overflow: hidden;
    height: auto;
    position: relative;
}
.to-top {
  cursor: pointer;
  padding: 20px;
  text-align: center;

}

.top-img {
  width: 35px;
}
.top-txt {
  color: #333;
  font-size: 14px;
}
.list-container {
    overflow-y: hidden;
    overflow-x: hidden;
    height: auto;
}
.list {
  margin-top: 30px;

  .query-box {
    margin: 30px 0;

    .category-box {
      line-height: 30px;
      padding: 10px 0;
    }

    .city-box {
      line-height: 30px;
      padding: 10px 0;
    }

    .title {
      display: inline-block;
      cursor: pointer;
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
    }

    .content {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.pagination {
  text-align: right;
  margin: 20px 0;
}

.item-card-simple {
  margin-bottom: 20px;
  padding: 20px;
}

.item-card {
  display: inline-block;
  width: 49%;
  margin-bottom: 20px;
  
 .cover-img {
   background: #f5f5f5;
   width: 250px;
   height: 150px;
 }

  .item-content {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .item-title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      flex: 1;
      
    }
    
    .item-tags {
      
      flex: 1;
      font-size: 16px;
      color: rgb(93, 170, 243);
    }

    .item-address {
      flex: 1;
      font-size: 14px;
    }
  }
}

.item-box {
  display: flex;
}

.mobile-box {
  cursor: pointer;
  color: rgb(253, 46, 46);
}

.mobile {
  cursor: pointer;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  color: rgb(253, 46, 46);
  font-size: 18px;
}

.mobile-dialog {
  padding: 20px;
  text-align: center;
}

.mobile-content {
  font-size: 30px;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.mobile-desc {
  font-size: 16px;
  color: gray;
}
.mobile-second {
  color: red;
  font-size: 16px;
}
.tag-item {
  margin-right: 15px;
}
.bottom-box {
  padding: 20px;
  font-size: 20px;
  color: gray;
  text-align: center;
}
</style>