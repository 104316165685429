<template>
  <div>
    <el-drawer
      title="查看项目详情"
      size="800px"
      :wrapperClosable="false"
      :visible.sync="checkInfo"
      direction="rtl"
      :before-close="handleClose">
      <div class="drawer-container">
        <el-form :model="form" status-icon ref="form" class="commodity-form" :label-position="labelPosition"> 
              <el-form-item prop="username" label="用户名称" :label-width="formLabelWidth">
                <el-input v-model="form.username" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="mobile" label="手机号" :label-width="formLabelWidth">
                <el-input v-model="form.mobile" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="service_desc" label="服务介绍" :label-width="formLabelWidth">
                <el-input v-model="form.service_desc" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="address" label="地址" :label-width="formLabelWidth">
                <el-input v-model="form.address" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="status" label="审核状态" :label-width="formLabelWidth">
                <el-input v-model="form.status" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="skill_type" label="技能分类" :label-width="formLabelWidth">
                <el-input v-model="form.skill_type" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="skill_label" label="技能标签" :label-width="formLabelWidth">
                <el-input v-model="form.skill_label" class="width350" disabled></el-input>
              </el-form-item>
              <el-form-item prop="update_time" label="更新时间" :label-width="formLabelWidth">
                <el-input v-model="form.update_time" class="width350" disabled></el-input>
              </el-form-item>
              
              <el-form-item label="艺术作品:" :label-width="formLabelWidth">
                <el-image class="content-item" v-for="(item, index) in form.contentList" :key="index" :src="item.url" fit="fill">
                </el-image>
              </el-form-item>
              
        </el-form>
      </div>
      
    </el-drawer>
    
  </div>
</template>

<script>
import moment from 'moment';
import E from "wangeditor";
import qs from 'qs';
import {mapState} from 'vuex'
export default {
  data() {
    return {
      labelPosition: 'left',
      formLabelWidth: '100px',
      activeName: 'base',
      ruleEditor: null,
      form: {},
    }
  },
  computed: {
    ...mapState({
      checkInfo: (state) => state.project.checkInfo
    })
  },
  watch: {
    checkInfo(val) {
      console.log(val)
      if (val) {
        this.initForm()
        this.loadDetail()
      }
    }
  },
  mounted() {
  },
  methods: {
    initForm() {
      this.form = {
        title: '',
        subTitle: '',
        creator: '',
        publisher: '',
        coverImgContentId: '',
        totalNum: '',
        itSupport: '',
        platform: '',
        publishTime: '',
        price: '',
        saleTime: '',
        activityIntroduce: '',
        commodityIntroduce: '',
        publishIntroduce: '',
        rule: '',
        contentList: [],
        contents: ''
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
          this.closeCommonDrawer()
        })
        .catch(_ => {});
    },
    closeCommonDrawer() {
      this.$store.commit('project/close_check_drawer')
    },
    
    handleTabClick(tab) {
      if (this.activeName === 'rich') {
        this.initRichTextEditor('activityIntroduce')
        this.initRichTextEditor('commodityIntroduce')
        this.initRichTextEditor('publishIntroduce')
        this.initRichTextEditor('rule')
      }
    },
    loadDetail() {
      let id = this.$store.state.project.currentId
      this.axios({
        method: 'get',
        url: '/nrb_manage/project/get_project_info',
        params: {
          id: id
        }
      }).then(res => {
        if (res.data && res.data.code === 'A00000') {
          this.form = res.data
        }
      })
    }
  }

}
</script>

<style scoped>
.drawer-container {
  width: 90%;
  margin: auto;
}
.commodity-form {
  width: 100%;
  margin-bottom: 40px;
}

.el-form-item__content {
  z-index: 998;
}

#commodityIntroduce {
  z-index: 998;
}

#publishIntroduce {
  z-index: 998;
}

.width350 {
  width: 350px;
}

.drawer-footer {
  position: fixed;
  bottom: 15px;
  z-index: 999;
}
.drawer-footer-button-group{
  display: flex;
}
.drawer-button-footer {
  flex: 1;
}

.rich-area {
  background-color: aliceblue;
  padding: 15px 30px;
  border-radius: 15px;
  margin-top: 15px;
}

.content-item {
  width: 200px;
  height: 200px;
}
</style>